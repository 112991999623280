import React from "react";
import { AddToCart } from "./AddToCart.tsx";
import { PopupImage } from "./PopupImage.tsx";

interface TileProps {
  id: string;
  imageUrl: string;
  price: number;
  description: string;
}

export const Tile = (props: TileProps) => {
  return (
    <div className="tile">
      <div className="tile-image-container">
        <PopupImage imageUrl={props.imageUrl} />
      </div>
      <div className="tile-content">
        <h3>{props.description}</h3>
        <AddToCart price={props.price} id={props.id} />
      </div>
    </div>
  );
};
