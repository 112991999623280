import React, { useEffect, useState } from "react";
import { PopupImage } from "../Shop/Components/PopupImage.tsx";
import {
  checkCanAddAnotherToCart,
  useShoppingCart,
} from "../Hooks/useShoppingCart.tsx";
import { Product } from "../Context/ProductContext.tsx";

interface ShopListItemProps {
  stockId: string;
  imageUrl: string;
  price: number;
  description: string;
  // Used to hide elements when on the checkout screen
  onCheckout: boolean;
  products: Product[];
}

export const ShopListItem = (props: ShopListItemProps) => {
  const { stockId, imageUrl, price, description, onCheckout, products } = props;
  const {
    getCart,
    removeFromShoppingCart,
    addToShoppingCart,
    removeOneFromShoppingCart,
  } = useShoppingCart();
  // const shopData = useShopData() // react context provider

  const cart = getCart();
  const [stock, setStock] = useState(cart[stockId]);

  const handleRemoveClick = () => {
    removeFromShoppingCart(props.stockId);
    window.location.reload();
  };

  const handleQuantityIncrease = () => {
    addToShoppingCart(props.stockId, () => setStock(stock + 1));
  };
  const handleQuantityDecrease = () => {
    removeOneFromShoppingCart(props.stockId);
    setStock(stock - 1);
  };

  useEffect(() => {
    if (stock === 0) {
      handleRemoveClick();
    }
  }, [stock]);

  var classname = `shop-list-item ${
    onCheckout ? "shop-list-item-checkout" : ""
  }`;
  return (
    <div className={classname}>
      <div className="image-container">
        {/* <img src={imageUrl} /> */}
        <PopupImage imageUrl={imageUrl} class="image" />
      </div>
      <div className="shop-list-item-content">
        <h3 className="content-title">{description}</h3>

        <div className="quantity-section">
          Quantity:
          <div className="buttons">
            {!onCheckout && (
              <button onClick={handleQuantityDecrease}>
                <MinusSvg />
              </button>
            )}
            <span>{stock}</span>
            {!onCheckout && (
              <button
                onClick={handleQuantityIncrease}
                disabled={!checkCanAddAnotherToCart(products, stockId, stock)}
              >
                <PlusSvg />
              </button>
            )}
          </div>
        </div>

        <h3 className="total-price">
          <span className="total">TOTAL: </span>${(price * stock).toFixed(2)}
        </h3>

        {!onCheckout && (
          <button onClick={handleRemoveClick} className="remove-item">
            <TrashSvg />
          </button>
        )}
      </div>
      <div></div>
    </div>
  );
};

const PlusSvg = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="122.881px"
      height="122.88px"
      viewBox="0 0 122.881 122.88"
      // enable-background="new 0 0 122.881 122.88"
    >
      <g>
        <path d="M56.573,4.868c0-0.655,0.132-1.283,0.37-1.859c0.249-0.6,0.61-1.137,1.056-1.583C58.879,0.545,60.097,0,61.44,0 c0.658,0,1.287,0.132,1.863,0.371c0.012,0.005,0.023,0.011,0.037,0.017c0.584,0.248,1.107,0.603,1.543,1.039 c0.881,0.88,1.426,2.098,1.426,3.442c0,0.03-0.002,0.06-0.006,0.089v51.62l51.619,0c0.029-0.003,0.061-0.006,0.09-0.006 c0.656,0,1.285,0.132,1.861,0.371c0.014,0.005,0.025,0.011,0.037,0.017c0.584,0.248,1.107,0.603,1.543,1.039 c0.881,0.88,1.428,2.098,1.428,3.441c0,0.654-0.133,1.283-0.371,1.859c-0.248,0.6-0.609,1.137-1.057,1.583 c-0.445,0.445-0.98,0.806-1.58,1.055v0.001c-0.576,0.238-1.205,0.37-1.861,0.37c-0.029,0-0.061-0.002-0.09-0.006l-51.619,0.001 v51.619c0.004,0.029,0.006,0.06,0.006,0.09c0,0.656-0.133,1.286-0.371,1.861c-0.006,0.014-0.012,0.025-0.018,0.037 c-0.248,0.584-0.602,1.107-1.037,1.543c-0.883,0.882-2.1,1.427-3.443,1.427c-0.654,0-1.283-0.132-1.859-0.371 c-0.6-0.248-1.137-0.609-1.583-1.056c-0.445-0.444-0.806-0.98-1.055-1.58h-0.001c-0.239-0.575-0.371-1.205-0.371-1.861 c0-0.03,0.002-0.061,0.006-0.09V66.303H4.958c-0.029,0.004-0.059,0.006-0.09,0.006c-0.654,0-1.283-0.132-1.859-0.371 c-0.6-0.248-1.137-0.609-1.583-1.056c-0.445-0.445-0.806-0.98-1.055-1.58H0.371C0.132,62.726,0,62.097,0,61.44 c0-0.655,0.132-1.283,0.371-1.859c0.249-0.6,0.61-1.137,1.056-1.583c0.881-0.881,2.098-1.426,3.442-1.426 c0.031,0,0.061,0.002,0.09,0.006l51.62,0l0-51.62C56.575,4.928,56.573,4.898,56.573,4.868L56.573,4.868z" />
      </g>
    </svg>
  );
};
const MinusSvg = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="122.881px"
      height="9.737px"
      viewBox="0 0 122.881 9.737"
      // enable-background="new 0 0 122.881 9.737"
    >
      <g>
        <path d="M117.922,0.006C117.951,0.002,117.982,0,118.012,0c0.656,0,1.285,0.132,1.861,0.371c0.014,0.005,0.025,0.011,0.037,0.017 c0.584,0.248,1.107,0.603,1.543,1.039c0.881,0.88,1.428,2.098,1.428,3.441c0,0.654-0.133,1.283-0.371,1.859 c-0.248,0.6-0.609,1.137-1.057,1.583c-0.445,0.445-0.98,0.806-1.58,1.055v0.001c-0.576,0.238-1.205,0.37-1.861,0.37 c-0.029,0-0.061-0.002-0.09-0.006c-37.654,0-75.309,0.001-112.964,0.001c-0.029,0.004-0.059,0.006-0.09,0.006 c-0.654,0-1.283-0.132-1.859-0.371c-0.6-0.248-1.137-0.609-1.583-1.056C0.981,7.865,0.621,7.33,0.372,6.73H0.371 C0.132,6.154,0,5.525,0,4.869C0,4.215,0.132,3.586,0.371,3.01c0.249-0.6,0.61-1.137,1.056-1.583 c0.881-0.881,2.098-1.426,3.442-1.426c0.031,0,0.061,0.002,0.09,0.006C42.613,0.006,80.268,0.006,117.922,0.006L117.922,0.006z" />
      </g>
    </svg>
  );
};
const TrashSvg = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="109.484px"
      height="122.88px"
      viewBox="0 0 109.484 122.88"
      // enable-background="new 0 0 109.484 122.88"
    >
      <g>
        <path
          // fill-rule="evenodd"
          // clip-rule="evenodd"
          d="M2.347,9.633h38.297V3.76c0-2.068,1.689-3.76,3.76-3.76h21.144 c2.07,0,3.76,1.691,3.76,3.76v5.874h37.83c1.293,0,2.347,1.057,2.347,2.349v11.514H0V11.982C0,10.69,1.055,9.633,2.347,9.633 L2.347,9.633z M8.69,29.605h92.921c1.937,0,3.696,1.599,3.521,3.524l-7.864,86.229c-0.174,1.926-1.59,3.521-3.523,3.521h-77.3 c-1.934,0-3.352-1.592-3.524-3.521L5.166,33.129C4.994,31.197,6.751,29.605,8.69,29.605L8.69,29.605z M69.077,42.998h9.866v65.314 h-9.866V42.998L69.077,42.998z M30.072,42.998h9.867v65.314h-9.867V42.998L30.072,42.998z M49.572,42.998h9.869v65.314h-9.869 V42.998L49.572,42.998z"
        />
      </g>
    </svg>
  );
};
