import {
  collection,
  doc,
  getDocs,
  getFirestore,
  query,
  updateDoc,
  where,
  increment,
} from "firebase/firestore";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { app } from "../firebaseConfig.ts";

interface ProductsContext {
  refreshProducts: () => void;
  decrementStock: (firebaseId: string, quantity?: number) => void;
  products: Product[];
  allProducts: Product[];
}

export interface Product {
  imageUrl: string;
  price: number;
  stock: number;
  stockId: string;
  firebaseId: string;
  title: string;
}

export const ProductsContext = createContext<ProductsContext>({
  products: [],
  allProducts: [],
  refreshProducts: () => {},
  decrementStock: (firebaseId: string, quantity?: number) => {},
});

export const useProducts = () => useContext(ProductsContext);

const db = getFirestore(app);

interface Props {
  children: React.ReactNode;
}

export const ProductsProvider: React.FC<Props> = ({ children }) => {
  const [products, setProducts] = useState<Product[]>([]);
  const [allProducts, setAllProducts] = useState<Product[]>([]);

  const loadProducts = useCallback(async () => {
    const q = query(
      collection(db, "products"),
      where("imageUrl", "!=", "blank")
    );
    const querySnapshot = await getDocs(q);

    const loadedProducts: Product[] = [];
    querySnapshot.forEach((doc) => {
      loadedProducts.push({ ...(doc.data() as Product), firebaseId: doc.id });
    });

    setAllProducts(loadedProducts);

    setProducts(loadedProducts.filter((p) => p.stock !== 0));
  }, []);

  const decrementStock = (firebaseId: string, quantity?: number) => {
    const productRef = doc(db, "products", firebaseId);

    updateDoc(productRef, {
      stock: increment((quantity ?? 1) * -1),
    }).then(() => {
      loadProducts();
    });
  };

  useEffect(() => {
    loadProducts();
  }, []);

  return (
    <ProductsContext.Provider
      value={{
        products,
        allProducts,
        refreshProducts: loadProducts,
        decrementStock,
      }}
    >
      {children}
    </ProductsContext.Provider>
  );
};
