import React from "react";
import "./Navbar.less";
import { Link } from "react-router-dom";
import { CartIcon } from "./CartIcon.tsx";
import { SignInButton } from "./SignInButton.tsx";

export const Navbar = () => {
  return (
    <header className="navbar">
      <Link className="header" to="/">
        <span>Kiwi Bongs</span>
      </Link>

      <div className="icons">
        {/* <SignInButton /> */}
        <Link to="/cart">
          <CartIcon />
        </Link>
      </div>

      <div className="links-container">
        <div className="links">
          <Link to="/">Home</Link>
          {/* <Link to="/shop">Shop</Link> */}
          <Link to="/about">About</Link>
        </div>
      </div>

      <div className="border" />
    </header>
  );
};
