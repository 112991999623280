import React, { useEffect, useState } from "react";
import { useShoppingCart } from "../Hooks/useShoppingCart.tsx";
import { ShopListItem } from "./ShopListItem.tsx";
import { Link } from "react-router-dom";
import { useProducts } from "../Context/ProductContext.tsx";

interface CartProps {
  onCheckout?: boolean;
  useCartCache?: boolean;
}

export const Cart = (props: CartProps) => {
  const { getCart } = useShoppingCart();
  const [cartCache, setCartCache] = useState({});

  useEffect(() => {
    setCartCache(getCart());
  }, [props.useCartCache]);

  const { products, allProducts } = useProducts();

  const productsToShow = props.onCheckout ? allProducts : products;
  const cart = props.useCartCache ? cartCache : getCart();
  const keys = Object.keys(cart);

  const shopObjects = productsToShow.filter(
    (d) => keys.indexOf(d.stockId) !== -1
  );

  return (
    <div className="cart">
      {shopObjects.map((obj) => {
        return (
          <ShopListItem
            key={obj.stockId}
            description={obj.title}
            imageUrl={obj.imageUrl}
            price={obj.price}
            stockId={obj.stockId}
            onCheckout={props.onCheckout ?? false}
            products={productsToShow}
          />
        );
      })}

      {shopObjects.length > 0 && !props.onCheckout && (
        <div className="checkout-container">
          <Link className="checkout-link" to="/checkout">
            Checkout
          </Link>
        </div>
      )}

      {shopObjects.length === 0 && <h1>Your cart is empty</h1>}
    </div>
  );
};
