import { initializeApp } from "firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyCh8n_KTIYMxy1UIX34T3WflF5xJddGgho",
    authDomain: "kiwibongsshop.firebaseapp.com",
    projectId: "kiwibongsshop",
    storageBucket: "kiwibongsshop.appspot.com",
    messagingSenderId: "133724684761",
    appId: "1:133724684761:web:da07d66c8a3e45fb1c59c8",
    measurementId: "G-2F6Z2D8MSS"
  };

export const app = initializeApp(firebaseConfig);

