import React from "react";
import { useState } from "react";

interface PopupImageProps {
  imageUrl: string;
  class?: string;
}

export const PopupImage = (props: PopupImageProps) => {
  const [imageOpen, setImageOpen] = useState(false);

  return (
    <>
      <button
        className="popup-button"
        onClick={() => {
          setImageOpen(!imageOpen);
        }}
      >
        <img className={props.class ?? "tile-image"} src={props.imageUrl} />
      </button>

      {imageOpen && (
        <div
          className="popped-image"
          onClick={() => {
            setImageOpen(false);
          }}
        >
          <div className="img-container">
            <img className="" src={props.imageUrl} />
          </div>
          <button className="close-button">X</button>
        </div>
      )}
    </>
  );
};
