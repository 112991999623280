import React from "react";
import { Shop } from "../Shop/Shop.tsx";

export const Home = () => {
  return (
    <div className="home">
      <div className="missionStatement">
        <div>
          <span>
            Welcome to Kiwi Bongs! We're dedicated to providing high-quality
            bongs and other smoking accessories at unbeatable prices. The
            mission is simple: to offer the best deals on water pipes and other
            accessories, ensuring you get the most value for your money. By
            sourcing our products directly and operating out of a private
            address in Waldronville, we can sell bongs for significantly less
            than traditional retailers.
          </span>
        </div>
      </div>
      <br />
      <br />
      <Shop />
    </div>
  );
};
