import React from "react";

interface AgeWarningProps {
  setShowWarning: (state: boolean) => void;
}

export const AgeWarning = (props: AgeWarningProps) => {
  return (
    <div className="ageWarning">
      <div className="warning-container">
        <span>
          This site is <span className="only">18+ Only</span>. Accept if you are
          18+ years of age
        </span>

        <div className="buttonContainer">
          <div className="button">
            <button
              onClick={() => {
                props.setShowWarning(false);
                localStorage.setItem("18+Accepted", "true");
              }}
            >
              Accept
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
