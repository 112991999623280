import React, { useEffect, useState } from "react";
import { Cart } from "../Cart/Cart.tsx";
import { useShoppingCart } from "../Hooks/useShoppingCart.tsx";
import { app } from "../firebaseConfig.ts";
import { addDoc, collection, getFirestore } from "firebase/firestore";
import { useProducts } from "../Context/ProductContext.tsx";

const db = getFirestore(app);
const orders = collection(db, "orders");
const products = collection(db, "products");

export const Checkout = () => {
  const [orderPlaced, setOrderPlaced] = useState(false);
  const { getCartTotalPrice, getCart, clearCart } = useShoppingCart();
  const { decrementStock, products } = useProducts();

  const [cartTotal, setCartTotal] = useState("");
  useEffect(() => {
    setCartTotal(getCartTotalPrice());
  }, []);

  const handlePlaceOrder = async (e) => {
    e.preventDefault();

    await addDoc(orders, {
      customerName: e.target[0].value,
      customerContact: e.target[1].value,
      orderTotal: cartTotal,
      orderItems: JSON.stringify(getCart()),
      orderDate: new Date(),
    });

    const cart = getCart();
    const keys = Object.keys(cart);
    const purchasedProducts = products.filter(
      (d) => keys.indexOf(d.stockId) !== -1
    );

    purchasedProducts.forEach((product) => {
      decrementStock(product.firebaseId, cart[product.stockId]);
    });

    setOrderPlaced(true);
    clearCart();
  };

  return (
    <div className="checkout">
      <Cart onCheckout={true} useCartCache={true} />

      <h2 className="grand-total">Grand Total: {cartTotal}</h2>

      {!orderPlaced && cartTotal != "$0.00" && (
        <div className="checkout-form-container">
          <form className="checkout-form" onSubmit={handlePlaceOrder}>
            <label htmlFor="nameInput">Name*</label>
            <input id="nameInput" title="Name" type="text" required />

            <label htmlFor="contactInput">Email or Phone*</label>
            <input id="contactInput" title="Contact" type="text" required />

            <button className="submitButton" type="submit">
              Place Order
            </button>
          </form>
        </div>
      )}

      {orderPlaced && (
        <div className="order-placed">
          <h1 className="order-success">
            Order Success - Your order has been placed!{" "}
          </h1>
          <h2 className="order-confirmation">
            Please make payment to the following Bank Account:
          </h2>
          <br />
          <h1>K G BRETT</h1>
          <h1>02-0910-0062833-001</h1>
          <br />
          <h3>
            Once payment has been received and your order is prepared, you will
            receive an email or text with the exact address to pick up from.
          </h3>

          <h3>Thank you!</h3>
        </div>
      )}
    </div>
  );
};
