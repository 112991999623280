import './App.css';
import { Navbar } from './Navbar/Navbar.tsx';
import { Routes, Route } from 'react-router-dom';
import { Home } from './Home/Home.tsx';
import { Footer } from './Footer/Footer.tsx';
import { About } from './About/About.tsx';
import { Shop } from './Shop/Shop.tsx';
import { useState } from 'react';
import { AgeWarning } from './AgeWarning.tsx';
import { firebaseConfig } from './firebaseConfig.ts';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { app } from './firebaseConfig.ts';
import { Cart } from './Cart/Cart.tsx';
import { Checkout } from './Checkout/Checkout.tsx';
import { getFirestore, collection, addDoc } from 'firebase/firestore/lite';

const db = getFirestore(app);

function App() {
  const [showWarning, setShowWarning] = useState(!localStorage.getItem("18+Accepted") ?? true);
  if (showWarning) {
    return AgeWarning({setShowWarning});
  }

  return (
    <div className="app">
      <Navbar />

      <div className="content">
        <Routes>
          <Route path="/*" element={<Home />} />
          {/* <Route path="/shop" element={<Shop />} /> */}
          <Route path="/about" element={<About />} />
          <Route path="/sign-in" element={<h1 className="comingSoon">Coming soon!</h1>} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/checkout" element={<Checkout />} />
        </Routes>
      </div>
      
      <Footer />
    </div>
  );
}

export default App;
