import React from "react";

export const About = () => {
  return (
    <div className="about">
      <div className="text">
        <div className="textcontent">
          <div className="section">
            <h1 className="intro">About Kiwi Bongs</h1>
            <span>
              Welcome to Kiwi Bongs, your one-stop destination for high-quality
              bongs and other smoking accessories at unbeatable prices. Our
              mission is to offer the best deals on water pipes and related
              goods, ensuring you get the most value for your money.
            </span>
          </div>
          <div className="section">
            <h1>Our Story</h1>
            <span>
              Kiwi Bongs was born out of a simple idea: to provide premium
              smoking accessories at prices that won't break the bank. We
              noticed that many local shops were charging exorbitant prices for
              bongs and other smoking essentials, and we knew there had to be a
              better way. By operating out of a private address in Waldronville
              and keeping our overhead costs low, we can offer these premium
              products at prices well below those of traditional retailers.
            </span>
          </div>
          <div className="section">
            <h1>Our Mission</h1>
            <span>
              At Kiwi Bongs, the goal is to beat the prices of other water pipe
              suppliers without compromising on quality. We believe that
              everyone should have access to top-notch smoking accessories at
              affordable prices. Our carefully curated selection only includes
              proven glassware & accessories, that will make themselves at home
              in your collection
            </span>
          </div>
          <div className="section">
            <h1>Why Choose Us?</h1>
            <ul>
              <li>
                <strong>Unbeatable Prices</strong>: We offer high-quality bongs
                and smoking accessories at prices you won't find anywhere else.
                By operating out of a private address, we keep our overheads
                extremely low, meaning minimal costs are passed on to you!
              </li>
              <li>
                <strong>Quality Assurance</strong>: Each product in our catalog
                is handpicked and listed, which ensures it always meets our high
                standards of quality
              </li>
              <li>
                <strong>Community Focused</strong>: As a locally operated
                business in Waldronville, we're committed to serving our
                community and building lasting relationships with our customers.
              </li>
            </ul>
          </div>
          <div className="section">
            <h1>Our Products</h1>
            <ul>
              <li>
                <strong>Bongs / Waterpipes</strong>: Available in various
                styles, sizes, and materials to suit every preference.
              </li>
              <li>
                <strong>Accessories</strong>: Including grinders, cleaning
                supplies, and Lighters to enhance your smoking experience.
              </li>
            </ul>
          </div>
          <div className="section">
            <h1>How to Order</h1>
            <span>
              Shop using the website and add the items you want to your cart.
              When ready, check out and make payment. Once your payment has been
              received, you will receive a text that your order is ready to pick
              up along with the location.
            </span>
          </div>
          <div className="section">
            <h1>How to Contact Us</h1>
            <span>
              For inquiries, product information and sales, or to request our
              address, please reach out to us via:
            </span>
            <ul>
              <li>
                <strong>Phone (Coming Soon)</strong>:{" "}
                <a href="tel:business number">Text or Call</a>
              </li>
              <li>
                <strong>Email (Coming Soon)</strong>:{" "}
                <a href="mailto:coming soon">Coming Soon</a>
              </li>
              {/* <li>
                <strong>Facebook (Coming Soon)</strong>:{" "}
                <a href="facebook.com">See us on Facebook</a>
              </li> */}
            </ul>
          </div>
        </div>
      </div>

      <GoogleMaps />
    </div>
  );
};

const GoogleMaps = () => {
  return (
    <div className="mapContainer">
      <iframe
        height="100%"
        width="100%"
        id="gmap_canvas"
        src="https://maps.google.com/maps?width=520&amp;height=402&amp;hl=en&amp;q=Waldronville%20Waldronville+()&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
      ></iframe>
      <script
        type="text/javascript"
        src="https://embedmaps.com/google-maps-authorization/script.js?id=ae2f996512ce9210aa1b2c230458ee787d259573"
      ></script>
    </div>
  );
};
