import React from "react";

import { Tile } from "./Components/Tile.tsx";
import { useProducts } from "../Context/ProductContext.tsx";

export const Shop = () => {
  const { products } = useProducts();

  return (
    <div className="tile-container">
      {products.map((product) => {
        return (
          <Tile
            key={product.stockId}
            imageUrl={product.imageUrl}
            description={product.title}
            price={product.price}
            id={product.stockId}
          />
        );
      })}

      {products.length === 0 && (
        <div className="out-of-stock">
          <h1>Looks like we're out of stock!</h1>
          <h2>Please check back soon</h2>
        </div>
      )}
    </div>
  );
};
