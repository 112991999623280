import { useEffect, useState } from "react";
import { Product, useProducts } from "../Context/ProductContext.tsx";

const LocalStorageCartKey = "kiwibongs-cart";

export const checkCanAddAnotherToCart = (
  products: Product[],
  id: string,
  quantity: number
) => {
  const stockInQuestion = products.find((obj) => obj.stockId === id);

  if (stockInQuestion === undefined) {
    return false;
  }

  if (quantity + 1 > stockInQuestion?.stock) {
    return false;
  }
  return true;
};

export const useShoppingCart = () => {
  const { products } = useProducts();

  const addToShoppingCart = (id: string, onSuccessCallback?: () => void) => {
    const itemsFromLocalStorage = localStorage.getItem(LocalStorageCartKey);
    var localStorageObject = itemsFromLocalStorage
      ? JSON.parse(itemsFromLocalStorage)
      : {};

    var quantity = 0;
    if (id in localStorageObject) {
      quantity = localStorageObject[id];
    }

    const quantityCheckPassed = checkCanAddAnotherToCart(
      products,
      id,
      quantity
    );
    if (quantityCheckPassed) {
      quantity = quantity + 1;
      if (onSuccessCallback) onSuccessCallback();
    } else {
      return; // Maybe display an error to the user
    }

    const newLocalStorage = {
      ...localStorageObject,
      [id]: quantity,
    };
    localStorage.setItem(LocalStorageCartKey, JSON.stringify(newLocalStorage));
  };

  const removeOneFromShoppingCart = (id: string) => {
    const itemsFromLocalStorage = localStorage.getItem(LocalStorageCartKey);
    var localStorageObject = itemsFromLocalStorage
      ? JSON.parse(itemsFromLocalStorage)
      : {};

    var quantity = 0;
    if (id in localStorageObject) {
      quantity = localStorageObject[id] - 1;
    }

    const newLocalStorage = {
      ...localStorageObject,
      [id]: quantity,
    };
    localStorage.setItem(LocalStorageCartKey, JSON.stringify(newLocalStorage));
  };

  const removeFromShoppingCart = (id: string) => {
    const itemsFromLocalStorage = localStorage.getItem(LocalStorageCartKey);
    if (!itemsFromLocalStorage) {
      return;
    }
    var localStorageObject = itemsFromLocalStorage
      ? JSON.parse(itemsFromLocalStorage)
      : {};

    delete localStorageObject[id];

    localStorage.setItem(
      LocalStorageCartKey,
      JSON.stringify(localStorageObject)
    );
  };

  const existsInCart = (id: string) => {
    const itemsFromLocalStorage = localStorage.getItem(LocalStorageCartKey);
    if (!itemsFromLocalStorage) {
      return false;
    }
    var localStorageObject = itemsFromLocalStorage
      ? JSON.parse(itemsFromLocalStorage)
      : {};

    if (id in localStorageObject) {
      return true;
    }
  };

  const getCart = () => {
    const itemsFromLocalStorage = localStorage.getItem(LocalStorageCartKey);
    var localStorageObject = itemsFromLocalStorage
      ? JSON.parse(itemsFromLocalStorage)
      : {};

    return localStorageObject;
  };

  const getCartTotalPrice = () => {
    const cart = getCart();
    const keys = Object.keys(cart);
    const shopObjects = products.filter((d) => keys.indexOf(d.stockId) !== -1);

    var total = 0;
    shopObjects.forEach((obj) => {
      total += obj.price * cart[obj.stockId];
    });

    return `$${total.toFixed(2)}`;
  };

  const clearCart = () => {
    localStorage.removeItem(LocalStorageCartKey);
  };

  return {
    addToShoppingCart,
    removeFromShoppingCart,
    removeOneFromShoppingCart,
    existsInCart,
    getCart,
    getCartTotalPrice,
    clearCart,
  };
};
